//import main library
import $ from 'jquery';
import jQuery from 'jquery';
import axios from 'axios';
import Echo from 'laravel-echo';
import 'laravel-datatables-vite';
import Pusher from 'pusher-js';
import Pace from "pace-js";
import Choices from 'choices.js';

//declare
    window.$ = $;
    window.jQuery = $;
    window.DataTable = DataTable;
    window.Choices = Choices;
    window.Chart = Chart;

    window.axios = axios;
    window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    window.Pusher = Pusher;
    window.Pace = Pace;
    window.Echo = new Echo({
        broadcaster: 'pusher',
        key: import.meta.env.VITE_PUSHER_APP_KEY,
        cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER ?? 'mt1',
        wsHost: import.meta.env.VITE_PUSHER_HOST ? import.meta.env.VITE_PUSHER_HOST : `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
        wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
        wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
        forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
        enabledTransports: ['ws', 'wss'],
});
